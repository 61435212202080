import React from "react";

const HeroText = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="text-center p-5">
          <h2>আয়েশের জন্য, আয়েশের পণ্য</h2>
          <hr />
          <p className="text-muted">
            এই স্লোগানে যাত্রা শুরু আয়েশ বিডি প্রাইভেট লিমিটেড নামে অনলাইন চেইন
            শপের। ‘আয়েশ ফুড’, ‘আয়েশ এগ্রো অ্যান্ড ডেইরি’, ‘আয়েশ
            হ্যান্ডিক্রাফটস’, ‘আয়েশ ফ্যাশন’, ‘আয়েশ হেলথ’, ‘আয়েশ কুরিয়ার’ এবং
            ‘আয়েশ আদারস’ ক্যাটাগরিতে জেনারেল সাপ্লায়ার লাইসেন্সে অনলাইন শপিং
            সার্ভিস হিসেবে সেবা দেয়ার লক্ষ্যেই প্রতিষ্ঠানটি কাজ শুরু করেছে।
            প্রতিষ্ঠানটির এক ঝাঁক তরুণ-তরুণী রাত-দিন ২৪ ঘণ্টা, ভোক্তাকে
            আন্তরিকভাবে সেবা দিতে প্রস্তুত থাকবে।
          </p>
          <p className="text-muted">
            আয়েশের সঙ্গে আগামীর পথচলা হোক সুন্দর আনন্দের; হোক আন্তরিকতা আর
            ভালোবাসার! আপনার স্বাচ্ছন্দের প্রতিটি মুহুর্তের অংশীদার হোক
            আমার-আপনার-সবার আয়েশ।
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroText;
