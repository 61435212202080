import React from "react";
import useProduct from "../../../../../Hook/useProduct";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const CropCare = () => {
  const { categories } = useProduct(
    `https://rocky-earth-23971.herokuapp.com/products?categories=${"crop-care"}`
  );
  return (
    <div className="container my-5">
      <div className="row">
        <p className="text-muted my-3">
          শস্য জাতীয় পণ্য আয়েশ প্যাডি প্লান্ট’র আওতায় আনা হয়েছে। ফলে দানাজতীয়
          বেশীরভাগ খাদ্যশস্য পাওয়া যাবে এ ক্যাটাগরিতে। এর মধ্যে রয়েছে- চাল যা
          আবার ঢেকিছাঁটা, সুগন্ধি, লাল চাল, সরু চাল, পোলাও চাল, ক্ষীরের চাল,
          পায়েশের চাল এবং ক্ষুদের চাল হিসেবে পাওয়া যাবে। ডালের মধ্যেও মসুর,
          ছোলা, মুগ, মটর, খেসারি এবং তেওড়া আলাদা আলাদা মিলবে। এছাড়া পাওয়া যাবে
          গমের লাল আটা।
        </p>
      </div>
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">Crop Care Products</p>
        <hr />
        {categories.map((data) => (
          <div className="col-sm-6 col-md-4 col-lg-3" key={data._id}>
            <div className="card h-100">
              <div className="card p-2  shadow">
                <img src={data.imageFile} alt="imageFile" height="200" />
              </div>
              <div className="p-3">
                <Link to="/">
                  <h5 className="text-capitalize mt-3">{data.productTitle}</h5>
                </Link>
                <div className="my-3">
                  <Rating name="read-only" value={3} readOnly />
                </div>
                <div className="d-flex justify-content-between">
                  <Button variant="contained">Add to cart</Button>
                  <Button variant="outlined">
                    <FavoriteBorderIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CropCare;
