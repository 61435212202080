import React from "react";
import useDatabase from "../../../Hook/useDatabase";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

const ActivityList = () => {
  const { axiosData, setAxiosData, axiosLoading, axiosError } = useDatabase(
    "https://rocky-earth-23971.herokuapp.com/activity"
  );
  if (axiosLoading) return <h1> LOADING...</h1>;

  if (axiosError) console.log(axiosError);
  const handleDeleteJob = (id) => {
    const proceed = window.confirm("Are you sure!");
    if (proceed) {
      const url = `https://rocky-earth-23971.herokuapp.com/activity/${id}`;

      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.warn("Successfully deleted 1 item");
            const remaining = axiosData.filter((data) => data._id !== id);
            setAxiosData(remaining);
          }
        });
    }
  };
  return (
    <div className="container-fluid min-vh-100">
      <div className="bg-white my-5 shadow p-2 border border-2">
        <div className="row">
          <div className="col-md-6 order-1 order-md-0">
            {/* <p className="text-muted pt-2">
          Total jobs [{" "}
          <span className="fw-bold text-danger">{totalJobs.length}</span> ]
          here is showing [{" "}
          <span className="fw-bold text-danger">{allJobs.length}</span> ]
          jobs
        </p> */}
          </div>
          {/* <div className="col-md-4 order-0 order-md-1">
        <div className="mb-3">
          <input
            type="text"
            className="form-control rounded-pill"
            placeholder="Search by required skill"
            onChange={handleSkillSearch}
          />
        </div>
      </div> */}
        </div>

        <div className="row">
          <div className="col mx-auto">
            <div className="table-responsive ">
              <table className="table table-sm table-hover table-bordered align-middle text-center">
                <thead className="table-primary">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col">Short Title</th>
                    <th scope="col">Image</th>
                    <th scope="col">Full Description</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {axiosData.map((data, index) => (
                    <tr key={data._id}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        {data.date}
                        <br />
                        {data.time}
                      </td>
                      <td>{data.shortTitle}</td>
                      <td>
                        <img
                          src={data.imageFile}
                          alt="imageFile"
                          style={{ width: "100px" }}
                        />
                      </td>
                      <td>{data.fullDescription.substring(0, 50)}</td>
                      <td>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDeleteJob(data._id)}
                        >
                          <DeleteForeverIcon />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/*  <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center pt-3">
            {[...Array(pageCount).keys()].map((number) => (
              <li className="page-item" key={number}>
                <button
                  type="button"
                  className={
                    number === page
                      ? "btn btn-dark ms-3"
                      : "btn btn-outline-dark ms-3"
                  }
                  onClick={() => setPage(number)}
                >
                  {number + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityList;
