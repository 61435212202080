import React from "react";

const BabyBoy = () => {
  return (
    <div className="container my-5">
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">Baby Boy</p>
        <hr />
      </div>
    </div>
  );
};

export default BabyBoy;
