import React from "react";
import Hero from "../Containers/Hero";
import Navbar from "../Containers/Navbar";
import TopBar from "../Containers/TopBar";

const Header = () => {
  return (
    <header>
      <TopBar />
      <Hero />
      <Navbar />
    </header>
  );
};

export default Header;
