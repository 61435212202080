import React from "react";
import useProduct from "../../../../Hook/useProduct";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const GentsFashion = () => {
  const { categories } = useProduct(
    `https://rocky-earth-23971.herokuapp.com/products?categories=${"gents-fashion"}`
  );
  return (
    <div className="container my-5">
      <div className="row">
        <p className="text-muted my-3">
          এক্সপোর্ট-ইমপোর্ট-ডিজাইনার দিয়ে তৈরি করা নিজস্ব পণ্য সবিকছু থাকবে আয়েশ
          জেন্টস এর মধ্যে । চোখ বুলিয়ে নেয়া যাক পণ্যের তালিকায়। থাকবে- *টি-শার্ট
          *পলো-শার্ট *আন্ডার গার্মেন্টস *শার্ট *প্যান্ট *জিনস *গ্যাবাডিং
          *থ্রি-কোয়ার্টার *ট্রাউজার *স্যান্ডো গেঞ্জি *লুঙ্গি *শীতের পোশাক
          *স্পোর্টস আইটেম
        </p>
        <p>
          ## বিখ্যাত সব ব্রান্ডের পণ্যের সাইজ আর কালার বলে দিলে বা ছবি পাঠালে
          সেটিও সংগ্রহ করে কুরিয়ার সার্ভিসে পৌছে দেয়া হবে আয়েশের ভোক্তাকে।
        </p>
      </div>
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">Gents Fashion</p>
        <hr />
        {categories.map((data) => (
          <div className="col-sm-6 col-md-4 col-lg-3" key={data._id}>
            <div className="card h-100">
              <div className="card p-2  shadow">
                <img src={data.imageFile} alt="imageFile" height="200" />
              </div>
              <div className="p-3">
                <Link to="/">
                  <h5 className="text-capitalize mt-3">{data.productTitle}</h5>
                </Link>
                <div className="my-3">
                  <Rating name="read-only" value={3} readOnly />
                </div>
                <div className="d-flex justify-content-between">
                  <Button variant="contained">Add to cart</Button>
                  <Button variant="outlined">
                    <FavoriteBorderIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GentsFashion;
