import React from "react";
import ContactText from "../../../Containers/ContactText";

const EventManagementFirm = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <h5 className="text-muted mb-4">আয়েশ ইভেন্ট ম্যানেজমেন্ট—</h5>
        <div className="text-muted">
          <h6 className="fw-bold ms-3 ">ইভেন্ট-</h6>
          <p className="my-3">
            বিভিন্ন প্রকারের ইভেন্ট যেমন- করপোরেট শো, করপোরেট মেলা, আর্থিক
            অন্তভূক্তিমূলক অনুষ্ঠান, বার্ষিক সাধারণ সভা, করপোরেট মিটিংসহ
            অন্যান্য সভা, প্রদর্শনী, সেমিনার, সমাবর্তন, ওয়ার্কশপ, গায়েহলুদ,
            বৌভাত, বিবাহবার্ষিকী, জন্মদিন, সুন্নতেখাৎনা, কনসার্ট, ডিজে পার্টি,
            সাংস্কৃতিক অনুষ্ঠান, ফ্যাশন-শো, ডিজে পার্টি, পিকনিক, ভ্রমণ
          </p>
        </div>
        <div className="text-muted">
          <h6 className="fw-bold ms-3 ">ইভেন্টে যেসব সেবা থাকবে-</h6>
          <p className="my-3">
            ভেন্যু নির্বাচন, খাবার, পরিবহন, ক্যাটারিং, সাউন্ড সিস্টেম, লাইটিং,
            পোস্টারিং, ব্যানার, ফুল, মিডিয়া কভারেজ, বিজ্ঞাপন, আমন্ত্রণপত্র
            ছাপানো, আমন্ত্রণ জানানো, ডেকোরেশন, অতিথিদের অভ্যর্থনা জানানো,
            অনুষ্ঠান উপস্থাপনা, বেলুন দিয়ে সাজানো, শৈল্পিক বাতির বর্নিল ব্যবহার,
            ব্যাকড্রপস, স্ক্রীনিং এবং টিভি সেট আপ, ফটোগ্রাফি, ভিডিওগ্রাফি,
            স্লাইড-শো ইকুইপমেন্ট, কিড অ্যান্ড ফানি ওয়েবসাইট, ব্যাক প্রজেক্টর
            অ্যান্ড ফ্রন্ট প্রজেক্টর, লাইভ ব্রডকাস্টিং, জোকার্স (টম অ্যান্ড
            জেরি, মিকি অ্যান্ড মিলি, ট্রেইন অ্যান্ড জাম্পিং), গাড়ী, মাইক্রোবাস
            এবং পিক আপ এ অতিথিসহ বিভিন্ন পরিবহনের ব্যবস্থা, বিয়ের আয়োজনে
            ক্যান্ডেল ডেকোরেশন, ক্যান্ডেল লাইট ডিনার, গ্রাম্য পরিবেশে সাজানো, ফল
            প্রদর্শনী, মেহেদী স্টেজ, হলুদের স্টেজ, টেবিল এবং কুশন টেবিল সাজানো,
            বিয়ে এবং অভ্যর্থনা অনুষ্ঠানে স্টেজ ডেকোরেশন, ডালা এবং কুলা সাজানোসহ
            নানাবিধ সেবা, অনুষ্ঠান চলাকালীন ব্যাকগ্রাউন্ড মিউজিক, ব্যান্ড
            পার্টি, ঢোল বাদক, ডিজে, কাওয়ালী সংগীতসহ দেশ এবং বিদেশের বিখ্যাত
            শিল্পীদের নাচ এবং গান।
          </p>
        </div>
        <div className="text-muted">
          <h6 className="fw-bold ms-3 ">বিয়ের আয়োজন-</h6>
          <p className="my-3">
            অনুষ্ঠান অনুযায়ী ভেন্যু, কমিউনিটি সেন্টারের সঙ্গে কন্ট্রাক্ট করা,
            গাড়ি ভাড়া করা, ডেকোরেশন, আপ্যায়ন, কার্ড ছাপানো/নিমন্ত্রণপত্র ছাপানো,
            খাবারের মেন্যু ঠিক করা, বর-কনের পাশাপাশি আত্মীয়-স্বজনের পোশাক
            কেনাকাটা, যানবাহনের ব্যবস্থা করা, স্টেজ সাজানো, তত্ত্ব গোছানো, ছবি,
            ভিডিও, হলুদ, ব্রাইডাল শাওয়ার, মেহেদি নাইট, বিয়ে, কাজী, বউভাত, বিয়ের
            আয়োজনে শৈল্পিক বাতির বর্ণিল ব্যবহার, ক্যান্ডেল ডেকোরেশন, ক্যান্ডেল
            লাইট ডিনার, গ্রাম্য পরিবেশ রুপে সাজানো, ফল প্রদর্শনী, মেহেদী স্টেজ,
            হলুদের স্টেজ, টেবিল এবং কুশন টেবিল সাজানো, বিয়ে এবং অভ্যর্থনা
            অনুষ্ঠানে স্টেজ ডেকোরেশন, ডালা এবং কুলা সাজানোসহ নানাবিধ সেবা, গানের
            জলসা, বাসর ঘর, রাজা বা রানির মতো হলে রাজকীয় সাজপোশাকের সঙ্গে বিয়ের
            আয়োজনে থাকবে সুসজ্জিত ঘোড়ার গাড়ি/ বিশাল হাতির পিঠে চড়ে বর-বউ,
            অনুষ্ঠানে ডিজে চাইলে, আরও কোনো অতিরিক্ত যন্ত্রপাতি, আলোকসজ্জা, কোন
            শিল্পী লাগলে ইত্যাদি।
          </p>
        </div>
        <ContactText />
      </div>
    </div>
  );
};

export default EventManagementFirm;
