import React from "react";
import Carousel from "react-bootstrap/Carousel";
import img001 from "../../Images/001.jpeg";
import img002 from "../../Images/002.jpeg";
import img003 from "../../Images/003.jpeg";

const HeroCarousel = () => {
  return (
    <div className="container my-4">
      <div className="row">
        <Carousel variant="light">
          <Carousel.Item>
            <img className="d-block w-100" src={img001} alt="slide01" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img002} alt="slide02" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img003} alt="slide03" />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default HeroCarousel;
