import React from "react";
import { Link, Outlet } from "react-router-dom";
import SideBar from "../Controller/SideBar";
import HomeIcon from "@mui/icons-material/Home";

const Dashboard = () => {
  return (
    <div className="container-fluid bg-light min-vh-100">
      <div className="row">
        <div className="col-sm-2 p-0">
          <div className="bg-dark text-center p-4">
            <Link to="/">
              <HomeIcon />
            </Link>
          </div>
          <SideBar />
        </div>
        <div className="col-sm-10 bg-light border-start">
          <div className="row">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
