import React from "react";
import useProduct from "../../../../Hook/useProduct";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const ArchitectureInterior = () => {
  const { products } = useProduct(
    "https://rocky-earth-23971.herokuapp.com/product"
  );
  return (
    <div className="container my-5">
      <div className="row">
        <p className="text-muted my-3">
          আয়েশের অনেক সম্মানিত ভোক্তা রয়েছেন যারা বিশ্বস্ত লোক আর বাজে মানুষের
          পাল্লায় পড়ে বাড়ি ও জমি কিনতে বা তৈরি করতে প্রতারণার শিকার হন। আপনার এই
          কাজের দায়িত্বটাও আয়েশ নিতে চায়। নির্ভাবনাময় ভোক্তাদের ভবন তৈরির নকশাসহ
          রাজউকের অনুমোদনের ব্যবস্থা করে দেবে আয়েশ; এ কাজে সহায়তা করবে
          বিশ্ববিদ্যালয়ের এক ঝাঁক তরুণ আর্কিটেকচার।এমনকী রেজিস্ট্রিসহ লিগাল
          অ্যাডভাইসরও থাকবে আয়েশের, যারা সুন্দর সমাধানের মাধ্যমে আপনার ভবন তৈরির
          কাজকে গতিশীল করবে। আয়েশের সঙ্গে আগামীর পথচলা হোক সুন্দর আনন্দের; হোক
          আন্তরিকতা আর ভালোবাসার! আপনার স্বাচ্ছন্দের প্রতিটি মুহুর্তের অংশীদার
          হোক আমার-আপনার-সবার আয়েশ।
        </p>
      </div>
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">
          Architecture & Interior Products
        </p>
        <hr />
        {products.map(
          (data) =>
            data.categories === "architecture-interior" && (
              <div className="col-sm-6 col-md-4 col-lg-3" key={data._id}>
                <div className="card h-100">
                  <div className="card p-2  shadow">
                    <img
                      src={`data:image/png;base64,${data.imageFile}`}
                      alt="imageFile"
                      height="200"
                    />
                  </div>
                  <div className="p-3">
                    <Link to="/">
                      <h5 className="text-capitalize mt-3">
                        {data.productTitle}
                      </h5>
                    </Link>
                    <div className="my-3">
                      <Rating name="read-only" value={3} readOnly />
                    </div>
                    <div className="d-flex justify-content-between">
                      <Button variant="contained">Add to cart</Button>
                      <Button variant="outlined">
                        <FavoriteBorderIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default ArchitectureInterior;
