import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Images/logo.png";

const Hero = () => {
  return (
    <>
      <hr className="text-muted" />
      <div className="container mb-4">
        <div className="row justify-content-between">
          <div className="col-md-4">
            <div className="text-center">
              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid" />
              </Link>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <form className="d-flex" role="search">
              <input
                className="form-control"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button className="btn btn-primary" type="submit">
                Search
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
