import React from "react";
import RegisterForm from "../Containers/RegisterForm";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const Register = () => {
  return (
    <>
      <Header />
      <main>
        <RegisterForm />
      </main>
      <Footer />
    </>
  );
};

export default Register;
