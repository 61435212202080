import { useEffect, useState } from "react";
import axios from "axios";

const useProduct = (url) => {
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productsError, setProductsError] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setProductsLoading(true);
    axios
      .get(url)
      .then((res) => {
        const count = res.data.count;
        const pageNum = Math.ceil(count / 10);
        setPageCount(pageNum);
        setProducts(res.data.totalProduct);
        setTotalCount(res.data.count);
        setCategories(res.data.myCategories);
        console.log(res.data);
      })
      .catch((err) => {
        setProductsError(err);
      })
      .finally(() => {
        setProductsLoading(false);
      });
  }, [url]);

  return {
    products,
    setProducts,
    productsLoading,
    productsError,
    pageCount,
    totalCount,
    categories,
  };
};

export default useProduct;
