import React from "react";
import useProduct from "../../../../../Hook/useProduct";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const BirdMeat = () => {
  const { categories } = useProduct(
    `https://rocky-earth-23971.herokuapp.com/products?categories=${"bird-meat"}`
  );

  return (
    <div className="container my-5">
      <div className="row">
        <p className="text-muted my-3">
          পাখি সদৃশ অনেক প্রাণীর মাংস আমরা খাই। তবে প্রাণিজ আমিষের বেশীরভাগ
          চাহিদা পূরণ করে এখন মুরগী। সেটি মাথায় রেখে বিভিন্ন অনুষ্ঠানে ঘরোয়া হোক
          আর বড় আয়োজনে হোক সব ধরনের মুরগী আমরা গাজীপুর থেকে সরাসরি কৃষকের কাছ
          থেকে এনে আয়েশের ভোক্তাদের সরবরাহ করবো। ফলে ৫০ কেজি বা ৫০টা থেকে শুরু
          করে তদুর্দ্ধ- পরিমান মুরগী সরবরাহের আদেশ দিলে ঠিকানা বরাবরে পৌছে
          যাবে।সরবরাহ করা হবে, ব্রয়লার মুরগী, লেয়ার মুরগী, সোনালি মুরগী (দেশী
          বলে কোন মুরগী ঢাকা বা তার আশেপাশে পাওয়া যায় না, সোনালি জাতের বিভিন্ন
          কালারের মুরগীই দেশী বলে চালিয়ে দেয় অসাধুরা), হাঁস, রাজহাঁস, কবুতর,
          কোয়েল এবং টার্কি।এসব পণ্যের দাম প্রতিনিয়ত ওঠা-নামা করে বলে দাম
          নির্ধারণ করা হয়নি। তবে ১৫ মে থেকে প্রতিদিনের দাম পণ্যের সঙ্গে দিয়ে
          দেয়া হবে।
        </p>
      </div>
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">Bird Meat Products</p>
        <hr />
        {categories.map((data) => (
          <div className="col-sm-6 col-md-4 col-lg-3" key={data._id}>
            <div className="card h-100">
              <div className="card p-2  shadow">
                <img src={data.imageFile} alt="imageFile" height="200" />
              </div>
              <div className="p-3">
                <Link to="/">
                  <h5 className="text-capitalize mt-3">{data.productTitle}</h5>
                </Link>
                <div className="my-3">
                  <Rating name="read-only" value={3} readOnly />
                </div>
                <div className="d-flex justify-content-between">
                  <Button variant="contained">Add to cart</Button>
                  <Button variant="outlined">
                    <FavoriteBorderIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BirdMeat;
