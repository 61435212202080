import React from "react";
import useProduct from "../../../../Hook/useProduct";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const Hobbies = () => {
  const { categories } = useProduct(
    `https://rocky-earth-23971.herokuapp.com/products?categories=${"hobbies"}`
  );
  return (
    <div className="container my-5">
      <div className="row">
        <p className="text-muted my-3">
          কথায় আছে শখের মূল্য আশি টাকা। শখের দাম টাকায় না; মূল্যায়ন আর
          আবেগ-অনুভূতি দিয়ে প্রকাশ করতে হয়। আয়েশের ভোক্তার আবেগ প্রতিষ্ঠানটির
          কাছে মূল্যায়নের মাপকাঠিতে প্রকাশ হবে। সেই চিন্তা থেকে এই ক্যাটাগরিতে
          শখের বিভিন্ন পণ্য, আভিজাত্যের রকমারি সব প্রোডাক্টে সাজানো থাকবে। আপনার
          শখের পণ্যটির কথা বলে রাখলে আমরা সেটি সংগ্রহ করে পৌঁছে দেবো আপনার
          কাছে। অভিজাত কোন পণ্য সংগ্রহ করতে চাইলেও আমাদের প্রচেষ্টা থাকবে তা
          সংগ্রহ করে আপনার কাছে পৌঁছে দেয়ার। এছাড়া আয়েশের নিজস্ব কিছু পণ্য থাকবে
          এ তালিকায়- *শো-পিছ *আল্লাহু খোদাই লকেট *মোবাইল সংযুক্ত ঘড়ি *ব্রান্ডের
          মোবাইল এবং *কৃষি উপকরণ ম্যাজিক হ্যাজ পাইপ।
        </p>
      </div>
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">hobbies Products</p>
        <hr />
        {categories.map((data) => (
          <div className="col-sm-6 col-md-4 col-lg-3" key={data._id}>
            <div className="card h-100">
              <div className="card p-2  shadow">
                <img src={data.imageFile} alt="imageFile" height="200" />
              </div>
              <div className="p-3">
                <Link to="/">
                  <h5 className="text-capitalize mt-3">{data.productTitle}</h5>
                </Link>
                <div className="my-3">
                  <Rating name="read-only" value={3} readOnly />
                </div>
                <div className="d-flex justify-content-between">
                  <Button variant="contained">Add to cart</Button>
                  <Button variant="outlined">
                    <FavoriteBorderIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hobbies;
