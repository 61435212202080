import React from "react";
import { NavLink, Link } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-md bg-light text-light shadow border-top">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <MenuIcon />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/beauty-health">
                  Beauty & Health
                </NavLink>
              </li>
              {/* Media & Event Firm */}
              <li className="nav-item dropdown mx-2">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Media & Event Firm
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/media-&-event-firm/media-&-ad"
                    >
                      Media & AD
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/media-&-event-firm/event-management-firm"
                    >
                      Event Management Firm
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* Close */}
              {/* Agro & Dairy Food */}
              <li className="nav-item dropdown mx-2">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown2"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Agro & Dairy Food
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                  <li>
                    <Link className="dropdown-item" to="#">
                      Animal Food
                      <span className="ms-2">
                        <KeyboardDoubleArrowRightIcon fontSize="small" />
                      </span>
                    </Link>
                    <ul className="dropdown-menu dropdown-submenu">
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/agro-&-dairy-food/animal-food/dairy-food"
                        >
                          Dairy Food
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/agro-&-dairy-food/animal-food/beef"
                        >
                          Beef
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/agro-&-dairy-food/animal-food/bird-meat"
                        >
                          Bird Meat
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/agro-&-dairy-food/animal-food/black-bengal"
                        >
                          Black Bengal
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/agro-&-dairy-food/animal-food/buffalo"
                        >
                          Buffalo
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/agro-&-dairy-food/animal-food/milk-&-milk-processing-food"
                        >
                          Milk & Milk Processing Food
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/agro-&-dairy-food/animal-food/fish-food"
                        >
                          Fish Food
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/agro-&-dairy-food/animal-food/mutton"
                        >
                          Mutton
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Crop Care-Vegetables & Fruits
                      <span className="ms-2">
                        <KeyboardDoubleArrowRightIcon fontSize="small" />
                      </span>
                    </Link>
                    <ul className="dropdown-menu dropdown-submenu">
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/agro-&-dairy-food/crop-care-vegetables-&-fruits/fruits-food"
                        >
                          Fruits Food
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/agro-&-dairy-food/crop-care-vegetables-&-fruits/crop-care"
                        >
                          Crop Care
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/agro-&-dairy-food/crop-care-vegetables-&-fruits/vegetables-food"
                        >
                          Vegetables Food
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Processing Food
                          <span className="ms-2">
                            <KeyboardDoubleArrowRightIcon fontSize="small" />
                          </span>
                        </Link>
                        <ul className="dropdown-menu dropdown-submenu">
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/agro-&-dairy-food/crop-care-vegetables-&-fruits/processing-food/spicy"
                            >
                              Spicy
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/* Close */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/courier">
                  Courier
                </NavLink>
              </li>
              {/* Fashion & Handicraft */}
              <li className="nav-item dropdown mx-2">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown2"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Fashion & Handicraft
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/fashion-&-handicraft/gents-fashion"
                    >
                      Gents Fashion
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/fashion-&-handicraft/ladies-fashion"
                    >
                      Ladies Fashion
                    </NavLink>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Babies Fashion
                      <span className="ms-2">
                        <KeyboardDoubleArrowRightIcon fontSize="small" />
                      </span>
                    </Link>
                    <ul className="dropdown-menu dropdown-submenu">
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/fashion-&-handicraft/babies-fashion/baby-boy"
                        >
                          Baby Boy
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/fashion-&-handicraft/babies-fashion/baby-girl"
                        >
                          Baby Girl
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/* Close */}
              {/* Service */}
              <li className="nav-item dropdown mx-2">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Service
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/service/hobbies">
                      Hobbies
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/service/architecture-interior"
                    >
                      Architecture & Interior
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/service/aviation">
                      Aviation
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* Close */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
