import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useDatabase from "../../../Hook/useDatabase";
import { toast } from "react-toastify";
import axios from "axios";

const AddActivity = () => {
  const [imageURL, setImageURL] = useState("");
  const { click, buttonRefresh } = useDatabase();
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    const shortTitle = data.shortTitle;
    const fullDescription = data.fullDescription;
    const imageFile = imageURL;
    const date = new Date().toDateString();
    const time = new Date().toLocaleTimeString();

    const activityData = {
      shortTitle,
      fullDescription,
      imageFile,
      date,
      time,
    };

    fetch("https://rocky-earth-23971.herokuapp.com/activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(activityData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.insertedId) {
          toast.success("Successfully uploaded 1 item");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    buttonRefresh();
    reset();
  };
  const handleImageUpload = (e) => {
    const imageData = new FormData();
    imageData.set("key", "680c8fe984f0f42a9c381c4573383f40");
    imageData.append("image", e.target.files[0]);

    axios
      .post("https://api.imgbb.com/1/upload", imageData)
      .then((res) => {
        setImageURL(res.data.data.display_url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-sm-8 p-0">
          <div className="card shadow p-3">
            <p className="display-6 text-center text-muted border-bottom">
              Upload your new activity
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
              <div className="row g-4 justify-content-center">
                <div className="col-sm-8">
                  <input
                    type="text"
                    id="shortTitle"
                    name="shortTitle"
                    className="form-control"
                    placeholder="Short Title"
                    {...register("shortTitle")}
                    required
                  />
                </div>
                <div className="col-sm-8">
                  <input
                    type="file"
                    id="formFile"
                    name="formFile"
                    className="form-control"
                    placeholder="last name"
                    {...register("formFile")}
                    onChange={handleImageUpload}
                    required
                  />
                </div>
                <div className="col-sm-8">
                  <textarea
                    id="fullDescription"
                    name="fullDescription"
                    className="form-control"
                    placeholder="Full Description (optional)"
                    rows="6"
                    {...register("fullDescription")}
                  ></textarea>
                </div>
              </div>
              <div className="text-center mt-4">
                <button
                  type="submit"
                  className="btn btn-dark px-5 d-block d-sm-inline mx-auto mb-3 mb-md-0"
                  disabled={click}
                >
                  Submit
                  <span className="ms-3">{/*  <FaTelegramPlane /> */}</span>
                </button>
                <button
                  type="reset"
                  className="btn btn-dark ms-3"
                  disabled={click}
                >
                  Reset
                  <span className="ms-2">{/*   <FaRemoveFormat /> */}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddActivity;
