import React from "react";
import useProduct from "../../../../../Hook/useProduct";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const Mutton = () => {
  const { categories } = useProduct(
    `https://rocky-earth-23971.herokuapp.com/products?categories=${"mutton"}`
  );
  return (
    <div className="container my-5">
      <div className="row">
        <p className="text-muted my-3">
          দুধ আর দুগ্ধজাত খাবারের জন্য আয়েশের আছে ডেইরি সব পণ্য।নিজস্ব ডেইরি
          খামারের দুধ থেকে এসব পন্য তৈরি হবে। যশোরের এই খামার থেকে প্রস্তুতিমূলক
          খাবার আসবে ঢাকায়।এসব পন্যের দাম ট্যাগে যুক্ত করা হচ্ছে
        </p>
      </div>
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">Mutton Products</p>
        <hr />
        {categories.map((data) => (
          <div className="col-sm-6 col-md-4 col-lg-3" key={data._id}>
            <div className="card h-100">
              <div className="card p-2  shadow">
                <img src={data.imageFile} alt="imageFile" height="200" />
              </div>
              <div className="p-3">
                <Link to="/">
                  <h5 className="text-capitalize mt-3">{data.productTitle}</h5>
                </Link>
                <div className="my-3">
                  <Rating name="read-only" value={3} readOnly />
                </div>
                <div className="d-flex justify-content-between">
                  <Button variant="contained">Add to cart</Button>
                  <Button variant="outlined">
                    <FavoriteBorderIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Mutton;
