import React from "react";
import { Link } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const FooterColumns = (props) => {
  return (
    <div className="col-sm-6 col-lg-3 align-self-center">
      <div className="d-flex justify-content-center">
        <div>
          <h4 className="mt-5">{props.Title}</h4>
          <ul className="list-unstyled mt-3">
            {props.Items.map((item, index) => (
              <li key={index} className="mb-2">
                <Link to={item.link} className="text-muted">
                  <span>
                    <ArrowRightIcon />
                  </span>{" "}
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterColumns;
