import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAuth from "../../Hook/useAuth";
import { useForm } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { userLogin } = useAuth();

  const location = useLocation();
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const email = data.email;
    const password = data.password;
    userLogin(email, password, navigate, location);
  };
  return (
    <div className="container my-5">
      <h3 className="text-center text-muted">Welcome, Please Sign In!</h3>
      <hr />
      <div className="row g-3 justify-content-center">
        <div className="col-lg-5 ">
          <h5 className="text-muted text-center">New Customer</h5>
          <div className="bg-light p-3 text-muted">
            <p>
              By creating an account on our website you will be able to shop
              faster, be up to date on an orders status, and keep track of the
              orders you have previously made.
            </p>
          </div>
          <div className="text-center mt-2">
            <Button variant="contained">
              <span className="me-2">
                <PersonAddIcon fontSize="small" />
              </span>{" "}
              Register
            </Button>
          </div>
        </div>
        <div className="col-lg-5">
          <h5 className="text-muted text-center">Returning Customer</h5>
          <div className="bg-light p-3 text-muted">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-3">
                <label htmlFor="email" className="col-sm-3 col-form-label">
                  Email
                </label>
                <div className="col-sm-9">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    {...register("email", {
                      required: "this field is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "does't match email format",
                      },
                    })}
                  />
                  {errors.email && (
                    <span
                      style={{ color: "red", fontSize: "12px" }}
                      role="alert"
                    >
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label htmlFor="password" className="col-sm-3 col-form-label">
                  Password
                </label>
                <div className="col-sm-9">
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      className="form-control"
                      {...register("password", {
                        required: "this field is required",
                      })}
                    />
                    <button
                      className="btn border text-muted"
                      type="button"
                      id="confirm_password"
                      onClick={() => setShowPassword((prevState) => !prevState)}
                    >
                      {!showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </button>
                  </div>
                  {errors.password && (
                    <span
                      style={{ color: "red", fontSize: "12px" }}
                      role="alert"
                    >
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="text-end mb-3">
                <Link to="/">Forgot password?</Link>
              </div>
              <div className="text-center">
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<LoginIcon />}
                >
                  Login
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
