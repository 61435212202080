import React from "react";

const DashboardHome = () => {
  return (
    <div>
      <p>coming soon!</p>
    </div>
  );
};

export default DashboardHome;
