import { useEffect, useState } from "react";
import axios from "axios";

const useDatabase = (url) => {
  const [click, setClick] = useState(false);
  const [axiosData, setAxiosData] = useState([]);
  const [axiosLoading, setAxiosLoading] = useState(false);
  const [axiosError, setAxiosError] = useState(null);

  useEffect(() => {
    setAxiosLoading(true);
    axios
      .get(url)
      .then((res) => {
        setAxiosData(res.data);
      })
      .catch((err) => {
        setAxiosError(err);
      })
      .finally(() => {
        setAxiosLoading(false);
      });
  }, [url]);

  const buttonRefresh = () => {
    setClick(true);
    setTimeout(function () {
      setClick(false);
    }, 5000);
  };
  return {
    click,
    buttonRefresh,
    axiosData,
    setAxiosData,
    axiosLoading,
    axiosError,
  };
};

export default useDatabase;
