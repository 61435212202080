import React from "react";
import HeroCarousel from "../Containers/HeroCarousel";
import HeroText from "../Containers/HeroText";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import OurActivity from "../Containers/OurActivity";

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <HeroCarousel />
        <HeroText />
        <OurActivity />
      </main>
      <Footer />
    </>
  );
};

export default Home;
