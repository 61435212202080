import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import useAuth from "../../Hook/useAuth";
import { Button } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";

const TopBar = () => {
  const { userLogout, admin, users } = useAuth();
  let navigate = useNavigate();
  const handleGoDashboard = () => {
    navigate("/dashboard");
  };
  return (
    <nav className="navbar navbar-expand">
      <div className="container p-0">
        <div className="navbar-brand d-none d-sm-block">
          <select className="form-select" aria-label="Default select example">
            <option defaultValue>Taka</option>
            <option value="dollar">US Dollar</option>
          </select>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {users || admin ? (
              <>
                <Button
                  variant="text"
                  color="error"
                  size="small"
                  onClick={userLogout}
                >
                  <LogoutIcon fontSize="small" />
                  <span className="d-none d-sm-block ms-1">Log Out</span>
                </Button>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <NavLink
                    className="nav-link active"
                    aria-current="page"
                    to="/register"
                  >
                    <div className="d-flex">
                      <HowToRegIcon fontSize="small" />
                      <span className="d-none d-sm-block ms-1">Register</span>
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/login">
                    <div className="d-flex">
                      <LoginIcon fontSize="small" />
                      <span className="d-none d-sm-block ms-1">Login</span>
                    </div>
                  </NavLink>
                </li>
              </>
            )}
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                <div className="d-flex">
                  <LoyaltyIcon fontSize="small" />
                  <span className="d-none d-sm-block ms-1">Wishlist(0)</span>
                </div>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                <div className="d-flex">
                  <ShoppingCartIcon fontSize="small" />
                  <span className="d-none d-sm-block ms-1">
                    Shopping cart(0)
                  </span>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      {admin && (
        <div>
          <Button
            variant="contained"
            color="warning"
            size="small"
            onClick={handleGoDashboard}
          >
            <DashboardIcon />
            <span className="d-none d-sm-block">Dashboard</span>
          </Button>
        </div>
      )}
    </nav>
  );
};

export default TopBar;
