import React from "react";
import ContactText from "../../../Containers/ContactText";

const MediaAD = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <h5 className="text-muted">আয়েশ মিডিয়া—</h5>
        <p className="text-muted my-3">
          আয়েশের রয়েছে একঝাঁক মিডিয়া কর্মী; যারা এই প্রতিষ্ঠানের উপদেষ্টা। তাদের
          সার্বিক সহযোগিতা আর প্রচেষ্টায় ভোক্তাদের মিডিয়া সম্পর্কিত বিভিন্ন সেবা
          দেয়া হবে। এর মধ্যে রয়েছে নিউজ কাভারেজ, প্রোগ্রাম চাঙ্ক ডেলিভারি,
          ডকুমেন্টারি প্রস্তুত, বিজ্ঞাপন প্রস্তুত, ভয়েস ডেলিভারীসহ মিডিয়া
          সম্পর্কিত সবকিছু। প্রচারেই প্রসার; বিজ্ঞাপনের এটা একটা প্রধান
          উদ্দেশ্য। আপনার সেই উদ্দেশ্যের পারদকে ষোল আনায় পূর্ণ করতে আয়েশ
          অ্যাডফার্ম আপনাকে দিবে বিজ্ঞাপনের সব ঝামেলা থেকে মুক্তি। স্থান কাল
          পাত্রভেদে সময় ও প্রতিষ্ঠান উপযোগী বিজ্ঞাপন বানিয়ে দেবে আয়েশ। এমনকী সেই
          বিজ্ঞাপন আয়েশের ইউটিউব চ্যানেল ও সামাজিক যোগযোগ মাধ্যমে প্রকাশ করে
          প্রচারের ব্যবস্থাও করবে। এ ক্যাটাগরিতে দেয়া হবে প্রতিষ্ঠানিক ও সামাজিক
          সচেতনতা থেকে শুরু করে বিভিন্ন দৈর্ঘের ডকুমেন্টারি তৈরির সেবাও। এছাড়া
          ভিডিও সম্পর্কিত সব সেবাও এ ক্যাটাগরিতে মিলবে।
        </p>
        <ContactText />
      </div>
    </div>
  );
};

export default MediaAD;
