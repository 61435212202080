import React from "react";
import useDatabase from "../../Hook/useDatabase";
import { Link } from "react-router-dom";
import OurActivitySpinner from "../Spinners/OurActivitySpinner";

const OurActivity = () => {
  const { axiosData, axiosLoading, axiosError } = useDatabase(
    "https://rocky-earth-23971.herokuapp.com/activity"
  );

  if (axiosLoading) return <OurActivitySpinner />;

  if (axiosError) console.log(axiosError);
  //activity-details
  return (
    <div className="container mb-5">
      <h2 className="text-center mb-3">Our Activities </h2>
      <hr />
      <div className="row g-4">
        {axiosData.map((data) => (
          <div className="col-sm-6 col-md-3 mb-4" key={data._id}>
            <Link to="/">
              <div className="card h-100 shadow">
                <img
                  src={data.imageFile}
                  className="card-img-top"
                  alt="imageFile"
                />
                <div className="card-body"></div>
                <h5 className="text-center border-top my-2">
                  {data.shortTitle}
                </h5>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurActivity;
