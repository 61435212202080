import React from "react";
import { NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ImageIcon from "@mui/icons-material/Image";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Button from "@mui/material/Button";

const sideBarData = [
  {
    title: "Dashboard",
    urlLink: "/dashboard",
    titleIcon: DashboardIcon,
    titleBgColor: "info",
  },
  {
    title: "Add Activity",
    urlLink: "/dashboard/add-new-activity",
    titleIcon: ImageIcon,
    titleBgColor: "info",
  },
  {
    title: "Activity List",
    urlLink: "/dashboard/activity-list",
    titleIcon: ImageSearchIcon,
    titleBgColor: "info",
  },
  {
    title: "Add Product",
    urlLink: "/dashboard/add-new-product",
    titleIcon: AddCircleIcon,
    titleBgColor: "info",
  },
  {
    title: "Product List",
    urlLink: "/dashboard/product-list",
    titleIcon: ListAltIcon,
    titleBgColor: "info",
  },
];

const SideBar = () => {
  return (
    <nav className="navbar navbar-expand-sm navbar-dark mt-4 mt-sm-0">
      <button
        className="navbar-toggler mb-3 mx-auto shadow bg-dark"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent2"
        aria-controls="navbarSupportedContent2"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse justify-content-center"
        id="navbarSupportedContent2"
      >
        <ul className="navbar-nav flex-column mb-2 mb-lg-0 px-5 px-sm-0">
          {sideBarData.map((data) => (
            <Button
              key={data.title}
              variant="contained"
              size="small"
              sx={{ my: 1, color: "white" }}
              startIcon={<data.titleIcon />}
              color={data.titleBgColor}
            >
              <li className="nav-item px-0 px-lg-4">
                <NavLink className="nav-link" to={data.urlLink}>
                  <span className="d-block d-sm-none d-lg-block text-capitalize">
                    {data.title}
                  </span>
                </NavLink>
              </li>
            </Button>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default SideBar;
