import React from "react";
import useProduct from "../../../../../Hook/useProduct";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const VegetablesFood = () => {
  const { categories } = useProduct(
    `https://rocky-earth-23971.herokuapp.com/products?categories=${"vegetables-food"}`
  );
  return (
    <div className="container my-5">
      <div className="row">
        <p className="text-muted my-3">
          আয়েশ ভেজিটেবল প্লান্ট এ বিলুপ্তপ্রায় পণ্যগুলো রাখা হয়েছে। তবে হাইব্রিড
          সংস্করণ না; দেশী মানে পুরোটাই দেশী। ফলে আগের সেই স্বাদ পাবেন আয়েশ
          ভোক্তারা। এ তালিকায় রাখা হয়েছে- *দেশী বেগুন (কাঁটাওয়ালা) *ডুমুর
          *কাঁঠালের ইচুড় *কলার মোচা *কলার তোড় *কাঁচাকলা *বিচি কলা *পানিকচু
          *মানকচু *কাটাকচু *কচোড়ির কচু *ওলকচু *ওলকচুর ডাটা *কচুমুখি *কচুর লতি
          *ঘাটকোল *কচু শাক *সজনে ডাটা *হেলেঞ্চা শাক *থানকুনি শাক *পেপোল শাক
          *পাতি লেবু *মেটে আলু *লেটুস পাতা *ব্রুকলি *ক্যাপসিকাম *শালগম
        </p>
      </div>
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">
          Vegetables Food Products
        </p>
        <hr />
        {categories.map((data) => (
          <div className="col-sm-6 col-md-4 col-lg-3" key={data._id}>
            <div className="card h-100">
              <div className="card p-2  shadow">
                <img src={data.imageFile} alt="imageFile" height="200" />
              </div>
              <div className="p-3">
                <Link to="/">
                  <h5 className="text-capitalize mt-3">{data.productTitle}</h5>
                </Link>
                <div className="my-3">
                  <Rating name="read-only" value={3} readOnly />
                </div>
                <div className="d-flex justify-content-between">
                  <Button variant="contained">Add to cart</Button>
                  <Button variant="outlined">
                    <FavoriteBorderIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VegetablesFood;
