import React from "react";
import useProduct from "../../../../../Hook/useProduct";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const FruitsFood = () => {
  const { categories } = useProduct(
    `https://rocky-earth-23971.herokuapp.com/products?categories=${"fruits-food"}`
  );
  return (
    <div className="container my-5">
      <div className="row">
        <p className="text-muted my-3">
          বিদেশি বিভিন্ন ফলের বিষক্রিয়ায় এখন দেশী ফল বেশ জনপ্রিয়। তারপরও ভেজাল
          এড়ানো কষ্টসাধ্য হওয়ায় বিভিন্ন পন্থা অবলম্বন করে ফল নিরাপদ করতে। কেননা
          অনিরাপদ ফলের আক্রোসের স্বীকার হন আমাদের ছোট ছেলে-মেয়েরা। সে কারনে আয়েশ
          নিজস্ব ও নির্ধারিত বাগান থেকে দেশী ফল সংগ্রহ করে ভোক্তাদের কাছে পৌছে
          দিতে তৎপর থাকবে। আয়েশ ফ্রুটস এর পণ্যের তালিকায় রয়েছে- *আম *আমড়া
          *কাঁঠাল *লিচু *জাম *কলা *সফেদা *কুলবরই *গোলবরই *টক বরই *কাঁচা তেতুল
          *পাকা তেতুল *ডাব/নারকেল *বাতাবি লেবু *পেয়ারা *পেঁপে *সব ধরণের আচার
        </p>
      </div>
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">Fruits Food Products</p>
        <hr />
        {categories.map((data) => (
          <div className="col-sm-6 col-md-4 col-lg-3" key={data._id}>
            <div className="card h-100">
              <div className="card p-2  shadow">
                <img src={data.imageFile} alt="imageFile" height="200" />
              </div>
              <div className="p-3">
                <Link to="/">
                  <h5 className="text-capitalize mt-3">{data.productTitle}</h5>
                </Link>
                <div className="my-3">
                  <Rating name="read-only" value={3} readOnly />
                </div>
                <div className="d-flex justify-content-between">
                  <Button variant="contained">Add to cart</Button>
                  <Button variant="outlined">
                    <FavoriteBorderIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FruitsFood;
