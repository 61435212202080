import React from "react";
import { Link } from "react-router-dom";
import FooterColumns from "./FooterColumns";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Information = [
  { name: "Sitemap", link: "/" },
  { name: "Shipping & returns", link: "/" },
  { name: "Privacy notice", link: "/" },
  { name: "Conditions of use", link: "/" },
  { name: "About us", link: "/" },
  { name: "Contact us", link: "/" },
];
const CustomerService = [
  { name: "Search", link: "/" },
  { name: "News", link: "/" },
  { name: "Blog", link: "/" },
  { name: "Recently viewed products", link: "/" },
  { name: "Compare products list", link: "/" },
  { name: "New products", link: "/" },
];
const MyAccount = [
  { name: "My account", link: "/" },
  { name: "Orders", link: "/" },
  { name: "Addresses", link: "/" },
  { name: "Shopping cart", link: "/" },
  { name: "Wishlist", link: "/" },
  { name: "Apply for vendor account", link: "/" },
];

const Footer = () => {
  return (
    <footer className="bg-light border-top">
      <div className="container-fluid">
        <div className="row">
          <FooterColumns key={1} Title="Information" Items={Information} />
          <FooterColumns
            key={2}
            Title="Customer Service"
            Items={CustomerService}
          />
          <FooterColumns key={3} Title="My Account" Items={MyAccount} />
          <div className="col-sm-6 col-lg-3 mx-auto">
            <div className="row mt-5">
              <h4>Follow us</h4>
              <div>
                <a
                  target="_blank"
                  href="https://www.facebook.com/ayesh.bd.39"
                  rel="noopener noreferrer"
                  className="mx-2"
                >
                  <FacebookRoundedIcon fontSize="large" />
                </a>
                <a
                  target="_blank"
                  href="https://twitter.com/AYESHBD247"
                  rel="noopener noreferrer"
                  className="mx-2"
                >
                  <TwitterIcon fontSize="large" color="info" />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCrfDF5KIxN58kMnPIO5UuIA"
                  rel="noopener noreferrer"
                  className="mx-2"
                >
                  <YouTubeIcon fontSize="large" color="error" />
                </a>
              </div>
            </div>
            <div className="row mt-5">
              <h4>Newsletter</h4>
              <div>
                <form className="d-flex" role="search">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Enter your email here..."
                    aria-label="Search"
                  />
                  <button className="btn btn-primary" type="submit">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-md-flex justify-content-between align-items-center">
        <div className="text-sm-center px-3">
          <small>
            Powered by
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://aynalhossain.com/"
            >
              aynalHossain
            </a>
          </small>
        </div>
        <div className="text-sm-center px-3">
          <small>
            Copyright &copy; {new Date().getFullYear()}
            <Link to="/"> ayeshbd.com </Link> | All rights reserved
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
