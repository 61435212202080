import React from "react";
import useProduct from "../../../../../Hook/useProduct";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const DairyFood = () => {
  const { categories } = useProduct(
    `https://rocky-earth-23971.herokuapp.com/products?categories=${"dairy-food"}`
  );

  return (
    <div className="container my-5">
      <div className="row">
        <p className="text-muted my-3">
          ফুড সেফটি নিয়ে কাজ করতে চায় আয়েশ। সেই দৃষ্টিকোণ থেকে আয়েশ ফুড
          ক্যাটাগরির সৃষ্টি। রকমারি খাবারের সম্ভার নিয়ে আয়েশ ফুড আপনাকে
          ব্যতিক্রমী অথচ নির্ভেজাল সেরা খাবারটা পৌঁছে দেবে। সেই লক্ষ্যে এগিয়ে
          যাচ্ছে আয়েশ। প্রতিটা পণ্য নিজস্ব ফার্মে উৎপাদন ও প্রক্রিয়াজাতকরণের পর
          আমরা ভোক্তাকে প্লট-ফ্লাটে বিভিন্ন প্রকারের খাবার পৌছে দেবো। দারুণ
          দারুণ খাবারে সমৃদ্ধ ডেইরি ফুডে আমরা রেখেছি- *ঘি *মাখন *পণির *দই *টক দই
          *মিষ্টি। নিজস্ব ডেইরি থেকে এসব পণ্য উৎপাদন করা হবে। দাম থাকবে পণ্যের
          ক্যাটালগের সাথে।
        </p>
      </div>
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">Dairy Food Products</p>
        <hr />
        {categories.map((data) => (
          <div className="col-sm-6 col-md-4 col-lg-3" key={data._id}>
            <div className="card h-100">
              <div className="card p-2  shadow">
                <img src={data.imageFile} alt="imageFile" height="200" />
              </div>
              <div className="p-3">
                <Link to="/">
                  <h5 className="text-capitalize mt-3">{data.productTitle}</h5>
                </Link>
                <div className="my-3">
                  <Rating name="read-only" value={3} readOnly />
                </div>
                <div className="d-flex justify-content-between">
                  <Button variant="contained">Add to cart</Button>
                  <Button variant="outlined">
                    <FavoriteBorderIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DairyFood;
