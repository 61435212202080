import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./Components/Authentication/context/AuthProvider";
import Home from "./Components/Views/Home";
import BeautyHealth from "./Components/Views/Pages/BeautyHealth";
import MediaAD from "./Components/Views/Pages/Media&EventFirm/MediaAD";
import EventManagementFirm from "./Components/Views/Pages/Media&EventFirm/EventManagementFirm";
import DairyFood from "./Components/Views/Pages/Agro&DairyFood/AnimalFood/DairyFood";
import Beef from "./Components/Views/Pages/Agro&DairyFood/AnimalFood/Beef";
import BirdMeat from "./Components/Views/Pages/Agro&DairyFood/AnimalFood/BirdMeat";
import BlackBengal from "./Components/Views/Pages/Agro&DairyFood/AnimalFood/BlackBengal";
import Buffalo from "./Components/Views/Pages/Agro&DairyFood/AnimalFood/Buffalo";
import MilkMilkProcessingFood from "./Components/Views/Pages/Agro&DairyFood/AnimalFood/MilkMilkProcessingFood";
import FishFood from "./Components/Views/Pages/Agro&DairyFood/AnimalFood/FishFood";
import Mutton from "./Components/Views/Pages/Agro&DairyFood/AnimalFood/Mutton";
import FruitsFood from "./Components/Views/Pages/Agro&DairyFood/CropCare-VegetablesFruits/FruitsFood";
import CropCare from "./Components/Views/Pages/Agro&DairyFood/CropCare-VegetablesFruits/CropCare";
import VegetablesFood from "./Components/Views/Pages/Agro&DairyFood/CropCare-VegetablesFruits/VegetablesFood";
import Spicy from "./Components/Views/Pages/Agro&DairyFood/CropCare-VegetablesFruits/ProcessingFood/Spicy";
import Courier from "./Components/Views/Pages/Courier";
import GentsFashion from "./Components/Views/Pages/Fashion&Handicraft/GentsFashion";
import LadiesFashion from "./Components/Views/Pages/Fashion&Handicraft/LadiesFashion";
import BabyBoy from "./Components/Views/Pages/Fashion&Handicraft/BabiesFashion/BabyBoy";
import BabyGirl from "./Components/Views/Pages/Fashion&Handicraft/BabiesFashion/BabyGirl";
import Hobbies from "./Components/Views/Pages/Service/Hobbies";
import ArchitectureInterior from "./Components/Views/Pages/Service/ArchitectureInterior";
import Aviation from "./Components/Views/Pages/Service/Aviation";
import DynamicPage from "./Components/Views/DynamicPage";
import Login from "./Components/Views/Login";
import Register from "./Components/Views/Register";
import RequireAuth from "./Components/Authentication/requireAuth/RequireAuth";
import Dashboard from "./Components/Views/Dashboard";
import DashboardHome from "./Components/Controller/Pages/DashboardHome";
import AddActivity from "./Components/Controller/Pages/AddActivity";
import ActivityList from "./Components/Controller/Pages/ActivityList";
import AddProduct from "./Components/Controller/Pages/AddProduct";
import ProductList from "./Components/Controller/Pages/ProductList";

function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/" element={<DynamicPage />}>
              <Route path="beauty-health" element={<BeautyHealth />} />
              <Route
                path="media-&-event-firm/media-&-ad"
                element={<MediaAD />}
              />
              <Route
                path="media-&-event-firm/event-management-firm"
                element={<EventManagementFirm />}
              />
              <Route
                path="agro-&-dairy-food/animal-food/dairy-food"
                element={<DairyFood />}
              />
              <Route
                path="agro-&-dairy-food/animal-food/beef"
                element={<Beef />}
              />
              <Route
                path="agro-&-dairy-food/animal-food/bird-meat"
                element={<BirdMeat />}
              />
              <Route
                path="agro-&-dairy-food/animal-food/black-bengal"
                element={<BlackBengal />}
              />
              <Route
                path="agro-&-dairy-food/animal-food/buffalo"
                element={<Buffalo />}
              />
              <Route
                path="agro-&-dairy-food/animal-food/milk-&-milk-processing-food"
                element={<MilkMilkProcessingFood />}
              />
              <Route
                path="agro-&-dairy-food/animal-food/fish-food"
                element={<FishFood />}
              />
              <Route
                path="agro-&-dairy-food/animal-food/mutton"
                element={<Mutton />}
              />
              <Route
                path="agro-&-dairy-food/crop-care-vegetables-&-fruits/fruits-food"
                element={<FruitsFood />}
              />
              <Route
                path="agro-&-dairy-food/crop-care-vegetables-&-fruits/crop-care"
                element={<CropCare />}
              />
              <Route
                path="agro-&-dairy-food/crop-care-vegetables-&-fruits/vegetables-food"
                element={<VegetablesFood />}
              />
              <Route
                path="agro-&-dairy-food/crop-care-vegetables-&-fruits/processing-food/spicy"
                element={<Spicy />}
              />
              <Route path="courier" element={<Courier />} />
              <Route
                path="fashion-&-handicraft/gents-fashion"
                element={<GentsFashion />}
              />
              <Route
                path="fashion-&-handicraft/ladies-fashion"
                element={<LadiesFashion />}
              />
              <Route
                path="fashion-&-handicraft/babies-fashion/baby-boy"
                element={<BabyBoy />}
              />
              <Route
                path="fashion-&-handicraft/babies-fashion/baby-girl"
                element={<BabyGirl />}
              />
              <Route path="service/hobbies" element={<Hobbies />} />
              <Route
                path="service/architecture-interior"
                element={<ArchitectureInterior />}
              />
              <Route path="service/aviation" element={<Aviation />} />
            </Route>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            {/* Dashboard */}
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            >
              <Route path="/dashboard" element={<DashboardHome />} />
              <Route
                path="/dashboard/add-new-activity"
                element={<AddActivity />}
              />
              <Route
                path="/dashboard/activity-list"
                element={<ActivityList />}
              />
              <Route path="add-new-product" element={<AddProduct />} />
              <Route path="product-list" element={<ProductList />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
