import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useDatabase from "../../../Hook/useDatabase";
import axios from "axios";

const AddProduct = () => {
  const [imageURL, setImageURL] = useState("");
  const { click, buttonRefresh } = useDatabase();
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    const productTitle = data.productTitle;
    const productPrice = data.productPrice;
    const imageFile = imageURL;
    const categories = data.categories;
    const fullDescription = data.fullDescription;
    const date = new Date().toDateString();
    const time = new Date().toLocaleTimeString();
    const rating = 3;

    const productsData = {
      productTitle,
      productPrice,
      imageFile,
      categories,
      fullDescription,
      date,
      time,
      rating,
    };

    if (categories === "none") {
      return alert("please choose product category");
    } else {
      fetch("https://rocky-earth-23971.herokuapp.com/products", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productsData),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.insertedId) {
            toast.success("Successfully uploaded 1 item");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    buttonRefresh();
    reset();
  };

  const handleImageUpload = (e) => {
    const imageData = new FormData();
    imageData.set("key", "680c8fe984f0f42a9c381c4573383f40");
    imageData.append("image", e.target.files[0]);

    axios
      .post("https://api.imgbb.com/1/upload", imageData)
      .then((res) => {
        setImageURL(res.data.data.display_url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-sm-8 p-0">
          <div className="card shadow p-3">
            <p className="display-6 text-center text-muted border-bottom">
              Upload new product
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
              <div className="row g-4 justify-content-center">
                <div className="col-sm-8">
                  <input
                    type="text"
                    id="productTitle"
                    name="productTitle"
                    className="form-control"
                    placeholder="Product Title"
                    {...register("productTitle")}
                    required
                  />
                </div>
                <div className="col-sm-8">
                  <input
                    type="number"
                    id="productPrice"
                    name="productPrice"
                    className="form-control"
                    placeholder="Product Price"
                    {...register("productPrice")}
                    required
                  />
                </div>
                <div className="col-sm-8">
                  <input
                    type="file"
                    id="formFile"
                    name="formFile"
                    className="form-control"
                    placeholder="last name"
                    {...register("formFile")}
                    onChange={handleImageUpload}
                    required
                  />
                </div>
                <div className="col-sm-8">
                  <select className="form-select" {...register("categories")}>
                    <option value="none">Choose product category</option>
                    <option value="beauty-health">Beauty & Health</option>
                    <option value="dairy-food">
                      Agro & Dairy Food/Animal Food/Dairy Food
                    </option>
                    <option value="beef">
                      Agro & Dairy Food/Animal Food/Beef
                    </option>
                    <option value="bird-meat">
                      Agro & Dairy Food/Animal Food/Bird Meat
                    </option>
                    <option value="black-bengal">
                      Agro & Dairy Food/Animal Food/Black Bengal
                    </option>
                    <option value="buffalo">
                      Agro & Dairy Food/Animal Food/Buffalo
                    </option>
                    <option value="milk-milk-processing-food">
                      Agro & Dairy Food/Animal Food/Milk & Milk Processing Food
                    </option>
                    <option value="fish-food">
                      Agro & Dairy Food/Animal Food/Fish Food
                    </option>
                    <option value="mutton">
                      Agro & Dairy Food/Animal Food/Mutton
                    </option>
                    <option value="fruits-food">
                      Agro & Dairy Food/Crop Care-Vegetables & Fruits/Fruits
                      Food
                    </option>
                    <option value="crop-care">
                      Agro & Dairy Food/Crop Care-Vegetables & Fruits/Crop Care
                    </option>
                    <option value="vegetables-food">
                      Agro & Dairy Food/Crop Care-Vegetables & Fruits/Vegetables
                      Food
                    </option>
                    <option value="spicy">
                      Agro & Dairy Food/Crop Care-Vegetables & Fruits/Processing
                      Food/Spicy
                    </option>
                    <option value="gents-fashion">
                      Fashion & Handicraft/Gents Fashion
                    </option>
                    <option value="ladies-fashion">
                      Fashion & Handicraft/Ladies Fashion
                    </option>
                    <option value="hobbies">Services/Hobbies</option>
                    <option value="architecture-interior">
                      Services/Architecture & Interior
                    </option>
                    <option value="aviation">Services/Aviation</option>
                  </select>
                </div>
                <div className="col-sm-8">
                  <textarea
                    id="fullDescription"
                    name="fullDescription"
                    className="form-control"
                    placeholder="Full Description"
                    rows="3"
                    {...register("fullDescription")}
                    required
                  ></textarea>
                </div>
              </div>
              <div className="text-center mt-4">
                <button
                  type="submit"
                  className="btn btn-dark px-5 d-block d-sm-inline mx-auto mb-3 mb-md-0"
                  disabled={click}
                >
                  Submit
                  <span className="ms-3">{/*  <FaTelegramPlane /> */}</span>
                </button>
                <button
                  type="reset"
                  className="btn btn-dark ms-3"
                  disabled={click}
                >
                  Reset
                  <span className="ms-2">{/*   <FaRemoveFormat /> */}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
