import React from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import LoginForm from "../Containers/LoginForm";

const Login = () => {
  return (
    <>
      <Header />
      <main>
        <LoginForm />
      </main>
      <Footer />
    </>
  );
};

export default Login;
