import React from "react";

const OurActivitySpinner = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="spinner-border text-primary my-4" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default OurActivitySpinner;
