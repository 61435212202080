import React, { useState } from "react";
import { Button } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useForm } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useAuth from "../../Hook/useAuth";
import { useNavigate } from "react-router-dom";
import useDatabase from "../../Hook/useDatabase";

const RegisterForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { createNewUser } = useAuth();
  const { click, buttonRefresh } = useDatabase();
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    const fullName = data.fullName;
    const email = data.email;
    const password = data.password;
    createNewUser(fullName, email, password, navigate);
    buttonRefresh();
  };
  return (
    <div className="container my-5">
      <h3 className="text-center text-muted">Register</h3>
      <hr />
      <h5 className="text-center text-muted mb-4">Your Personal Details</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row justify-content-center bg-light border-top">
          <div className="col-lg-6 ">
            <div className="py-5 text-muted">
              <div className="row mb-3">
                <label htmlFor="fullName" className="col-sm-4 col-form-label">
                  Full Name :
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    name="fullName"
                    {...register("fullName", {
                      required: "this field is required",
                      minLength: {
                        value: 4,
                        message: "min length 4 characters",
                      },
                    })}
                  />
                  {errors.fullName && (
                    <span
                      style={{ color: "red", fontSize: "12px" }}
                      role="alert"
                    >
                      {errors.fullName.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label htmlFor="email" className="col-sm-4 col-form-label">
                  Email :
                </label>
                <div className="col-sm-8">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    {...register("email", {
                      required: "this field is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "does't match email format",
                      },
                    })}
                  />
                  {errors.email && (
                    <span
                      style={{ color: "red", fontSize: "12px" }}
                      role="alert"
                    >
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5 className="text-center text-muted my-4">Your Password</h5>
        <div className="row justify-content-center bg-light border-top">
          <div className="col-lg-6 ">
            <div className="py-5 text-muted">
              <div className="row mb-3">
                <label htmlFor="password" className="col-sm-4 col-form-label">
                  Password :
                </label>
                <div className="col-sm-8">
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      className="form-control"
                      {...register("password", {
                        required: "this field is required",
                      })}
                    />
                    <button
                      className="btn border text-muted"
                      type="button"
                      id="password"
                      onClick={() => setShowPassword((prevState) => !prevState)}
                    >
                      {!showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </button>
                  </div>
                  {errors.password && (
                    <span
                      style={{ color: "red", fontSize: "12px" }}
                      role="alert"
                    >
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="confirmPassword"
                  className="col-sm-4 col-form-label"
                >
                  Confirm Password :
                </label>
                <div className="col-sm-8">
                  <div className="input-group">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirm_password"
                      name="confirm_password"
                      className="form-control"
                      {...register("confirm_password", {
                        required: "this field is required",
                        validate: (value) =>
                          value === getValues("password") ||
                          "password doesn't match",
                      })}
                    />
                    <button
                      className="btn border text-muted"
                      type="button"
                      id="confirm_password"
                      onClick={() =>
                        setShowConfirmPassword((prevState) => !prevState)
                      }
                    >
                      {!showConfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </button>
                  </div>
                  {errors.confirm_password && (
                    <span
                      style={{ color: "red", fontSize: "12px" }}
                      role="alert"
                    >
                      {errors.confirm_password.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-3">
          <Button
            type="submit"
            variant="contained"
            startIcon={<PersonAddIcon />}
            disabled={click}
          >
            Register
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
