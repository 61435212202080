import React from "react";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import PlaceIcon from "@mui/icons-material/Place";

const ContactText = () => {
  return (
    <>
      <hr className="text-muted" />
      <div className="container my-4">
        <div className="row justify-content-center g-4">
          <h6 className="text-muted">
            আয়েশের সঙ্গে আগামীর পথচলা হোক সুন্দর আনন্দের; হোক আন্তরিকতা আর
            ভালোবাসার! আপনার স্বাচ্ছন্দের প্রতিটি মুহুর্তের অংশীদার হোক
            আমার-আপনার-সবার আয়েশ।
          </h6>
          <p className="text-muted my-4 text-center">
            অর্ডার করতে রেজিস্ট্রেসন করতে হবে (নমুনা হিসেবে ইউটিউবে ভিডিও আছে)
            অথবা ফোন করতে হবে নিচের নাম্বারগুলোতে।
          </p>
          <div className="col-sm-4 text-muted">
            <div className="d-flex">
              <SmartphoneIcon fontSize="small" />
              <p>+880 1534101173</p>
            </div>
            <div className="d-flex">
              <SmartphoneIcon fontSize="small" />
              <p>+880 1974101173</p>
            </div>
            <div className="d-flex">
              <SmartphoneIcon fontSize="small" />
              <p>+880 1614101173</p>
            </div>
            <div className="d-flex">
              <SmartphoneIcon fontSize="small" />
              <p>+880 1841101173</p>
            </div>
          </div>
          <div className="col-sm-4 text-muted">
            <div className="h5 text-center mb-4">অথবা যোগাযোগ করতে হবে</div>
            <div className="d-flex">
              <PlaceIcon fontSize="small" />
              <p className="ms-2">
                কক্ষ নং-৭০৩, নাহার প্লাজা, ৩৭, বীর উত্তম সি. আর দত্ত রোড,
                হাতিরপুল, ঢাকা-১০০০
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactText;
