import React, { useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import useProduct from "../../../Hook/useProduct";

const ProductList = () => {
  const [page, setPage] = useState(0);
  const {
    products,
    setProducts,
    productsLoading,
    productsError,
    pageCount,
    totalCount,
  } = useProduct(
    `https://rocky-earth-23971.herokuapp.com/products?page=${page}&&size=${10}`
  );
  if (productsLoading) return <h1> Loading...</h1>;

  if (productsError) console.log(productsError);
  const handleDeleteJob = (id) => {
    const proceed = window.confirm("Are you sure!");
    if (proceed) {
      const url = `https://rocky-earth-23971.herokuapp.com/products/${id}`;

      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.warn("Successfully deleted 1 item");
            const remaining = products.filter((data) => data._id !== id);
            setProducts(remaining);
          }
        });
    }
  };
  const handleSkillSearch = (e) => {
    const inputValue = e.target.value;

    fetch(
      `https://rocky-earth-23971.herokuapp.com/products?search=${inputValue}`
    )
      .then((res) => res.json())
      .then((data) => console.log(data.searchResults));
  };

  return (
    <div className="container-fluid min-vh-100">
      <div className="bg-white my-5 shadow p-2 border border-2">
        <div className="row">
          <div className="col-md-6 order-1 order-md-0">
            <p className="text-muted pt-2">
              Total product [{" "}
              <span className="fw-bold text-danger">{totalCount}</span> ] here
              is showing [{" "}
              <span className="fw-bold text-danger">{products.length}</span>]
              product
            </p>
          </div>
          <div className="col-md-4 order-0 order-md-1">
            <div className="mb-3">
              <input
                type="text"
                className="form-control rounded-pill"
                placeholder="Search by categories"
                onChange={handleSkillSearch}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col mx-auto">
            <div className="table-responsive ">
              <table className="table table-sm table-hover table-bordered align-middle text-center">
                <thead className="table-primary">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col">Product Title</th>
                    <th scope="col">Product Price</th>
                    <th scope="col">Image</th>
                    <th scope="col">Categories</th>
                    <th scope="col">Full Description</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((data, index) => {
                    return (
                      <tr key={data._id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {data.date}
                          <br />
                          {data.time}
                        </td>
                        <td>{data.productTitle}</td>
                        <td>৳ {data.productPrice}</td>
                        <td>
                          <img
                            src={data.imageFile}
                            alt="imageFile"
                            style={{ width: "100px" }}
                          />
                        </td>
                        <td>{data.categories}</td>
                        <td>{data.fullDescription.slice(0, 40)}</td>
                        <td>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleDeleteJob(data._id)}
                          >
                            <DeleteForeverIcon />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center pt-3">
                {[...Array(pageCount).keys()].map((number) => (
                  <li className="page-item" key={number}>
                    <button
                      type="button"
                      className={
                        number === page
                          ? "btn btn-dark ms-3"
                          : "btn btn-outline-dark ms-3"
                      }
                      onClick={() => setPage(number)}
                    >
                      {number + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
