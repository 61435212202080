import React from "react";
import useProduct from "../../../../Hook/useProduct";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const Aviation = () => {
  const { products } = useProduct(
    "https://rocky-earth-23971.herokuapp.com/product"
  );
  return (
    <div className="container my-5">
      <div className="row">
        <p className="text-muted my-3">
          অযথা সময় নষ্ট কেন করবেন! আপনার চাকুরী কিংবা ব্যবসার এক একটা মুহূর্তের
          মূল্য আয়েশ বোঝে বলেই দেশে বিদেশের বিভিন্ন ট্যুরে আপনাকে সার্বিক
          সহযোগিতা করতে আয়েশ আপনার বিমান টিকেটের ব্যবস্থা করবে। আপনার কাজ আর
          ভ্রমনকে গতিশীল করতে টুরিস্টদের জন্য থাকবে আয়েশ অ্যাভিয়েশনও।
          এয়ারলাইন্সের বিমান টিকিট আপনি চাইলেই পেয়ে যাবেন যথাসময়ে।
        </p>
      </div>
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">Aviation Products</p>
        <hr />
        {products.map(
          (data) =>
            data.categories === "aviation" && (
              <div className="col-sm-6 col-md-4 col-lg-3" key={data._id}>
                <div className="card h-100">
                  <div className="card p-2  shadow">
                    <img
                      src={`data:image/png;base64,${data.imageFile}`}
                      alt="imageFile"
                      height="200"
                    />
                  </div>
                  <div className="p-3">
                    <Link to="/">
                      <h5 className="text-capitalize mt-3">
                        {data.productTitle}
                      </h5>
                    </Link>
                    <div className="my-3">
                      <Rating name="read-only" value={3} readOnly />
                    </div>
                    <div className="d-flex justify-content-between">
                      <Button variant="contained">Add to cart</Button>
                      <Button variant="outlined">
                        <FavoriteBorderIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default Aviation;
