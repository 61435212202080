import { useEffect, useState } from "react";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import firebaseInitialize from "../Components/Authentication/firebase/firebaseInitialize";
import { toast } from "react-toastify";

firebaseInitialize();

const useFirebase = () => {
  const [loggedInUser, setLoggedInUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [admin, setAdmin] = useState(false);
  const [users, setUsers] = useState(false);

  const auth = getAuth();

  const createNewUser = (fullName, email, password, navigate) => {
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        updateProfileName(fullName);
        saveClientsToDatabase(email, fullName);
        toast.success("Successfully create an account");
        navigate("/login");
      })
      .catch((error) => {
        const errorCode = error.code;
        alert(errorCode);
        // const errorMessage = error.message;
        // ..
      })
      .finally(() => setIsLoading(false));
  };

  const updateProfileName = (fullName) => {
    updateProfile(auth.currentUser, {
      displayName: fullName,
    })
      .then(() => {
        // Profile updated!
        // ...
      })
      .catch((error) => {
        // An error occurred
        // ...
      });
  };

  const userLogin = (email, password, navigate, location) => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setLoggedInUser(user);
        toast.success("Successfully login");
        const destination = location.state?.from || "/";
        navigate(destination);
      })
      .catch((error) => {
        const errorCode = error.code;
        alert(errorCode);
        // const errorMessage = error.message;
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedInUser(user);
      } else {
        setLoggedInUser({});
      }
      setIsLoading(false);
    });
    return () => unSubscribe;
  }, [auth]);

  const saveClientsToDatabase = (email, fullName) => {
    const user = { email: email, fullName: fullName, role: "users" };
    fetch("https://rocky-earth-23971.herokuapp.com/users", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    }).then();
  };

  useEffect(() => {
    let mounted = true;
    fetch(`https://rocky-earth-23971.herokuapp.com/users/${loggedInUser.email}`)
      .then((res) => res.json())
      .then((data) => {
        if (mounted) {
          setUsers(data.user);
          setAdmin(data.admin);
        }
      });
    return () => {
      mounted = false;
    };
  }, [loggedInUser.email]);

  const userLogout = () => {
    setIsLoading(true);
    signOut(auth)
      .then(() => {
        setLoggedInUser({});
      })
      .catch((error) => {
        // An error happened.
      })
      .finally(() => setIsLoading(false));
  };

  return {
    loggedInUser,
    setLoggedInUser,
    isLoading,
    setIsLoading,
    createNewUser,
    userLogin,
    admin,
    users,
    userLogout,
  };
};

export default useFirebase;
