import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../../Hook/useAuth";

const RequireAuth = ({ children, ...rest }) => {
  const { loggedInUser, isLoading, admin } = useAuth();

  let location = useLocation();

  if (isLoading) {
    return <p className="text-center p-3 m-3">Loading...</p>;
  }

  if (loggedInUser?.email && admin) {
    return children;
  }

  return <Navigate to="/" state={{ from: location }} />;
};

export default RequireAuth;
