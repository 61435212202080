import React from "react";
import ContactText from "../../Containers/ContactText";
import useProduct from "../../../Hook/useProduct";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const BeautyHealth = () => {
  const { categories } = useProduct(
    `https://rocky-earth-23971.herokuapp.com/products?categories=${"beauty-health"}`
  );
  return (
    <div className="container my-5">
      <div className="row">
        <h5 className="text-muted">আয়েশ হেলথ—</h5>
        <p className="text-muted my-3">
          নিজের চেষ্টা আর বোধ থেকে মানুষের আর্থিক উন্নতি হয়েছে; বেড়েছে তার ক্রয়
          ক্ষমতাও। সময়ের আবর্তে পাল্টেছে মানুষের অভ্যাস। একটা উদাহরণ দেয়া যেতে
          পারে- আগে টাকার অভাবে পুষ্টিকর খাবার খেতে না পারায় রক্তস্বল্পতা
          লো-প্রেসারসহ বিভিন্ন সমস্যায় ভুগতো মানুষ। অথচ এখন মানুষ স্থুলতায় ভোগে
          যা নিয়ন্ত্রণে মানুষ ব্যয় করছে। একটু পরিপাটি থাকতে প্রসাধনীসহ রকমারি সব
          পণ্যেও খরচ হচ্ছে দেদারসে টাকা। আপনার সুস্থতা ও পরিপাটির অংশ হতে চায়
          আয়েশ হেলথ।
        </p>
        <p className="text-muted my-3">
          তাই আপনার জন্য আয়েশ নিয়ে এসেছে- * ভারত থেকে আমদানি করা প্রসাধনী *
          অ্যালোভেরা * ডালসহ মেহেদি পাতা * হেয়ার গ্রো থেরাপি * ফেয়ার লুক * সুইট
          স্লিম বেল্ট * সান্ধি সুধা * বাই বাই পাইলস * গ্রোথ ওয়ান * ডক্টর মাধু
          আম্রিট * ফ্যাট কাটার * হট শাওয়ার * হট শেপার প্যান্ট * স্যানিটারি
          ন্যাপকিন * ডায়াপার বেবি অ্যান্ড এডাল্ট ডায়াপার।
        </p>
        <ContactText />
      </div>
      <div className="row g-4">
        <hr />
        <p className="text-center display-6 text-muted">
          Beauty & Health Products
        </p>
        <hr />
        {categories.map((data) => (
          <div className="col-sm-6 col-md-4 col-lg-3" key={data._id}>
            <div className="card h-100">
              <div className="card p-2 shadow">
                <img src={data.imageFile} alt="imageFile" height="200" />
              </div>
              <div className="p-3">
                <Link to="/">
                  <h5 className="text-capitalize mt-3">{data.productTitle}</h5>
                </Link>
                <div className="my-3">
                  <Rating name="read-only" value={3} readOnly />
                </div>
                <div className="d-flex justify-content-between">
                  <Button variant="contained">Add to cart</Button>
                  <Button variant="outlined">
                    <FavoriteBorderIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BeautyHealth;
/*data.categories = "beauty-&-health" && "yess";
 */
